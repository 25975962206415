import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Header } from './header';
import { Footer } from './footer';

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <body className="bg-gray-50 font-sans antialiased text-gray-800" />
      </Helmet>

      <div className="min-h-screen flex flex-col">
        <Header />
        <div className="flex-1">{children}</div>
        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
